* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.default-button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  padding: 8px 30px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.75em;
  letter-spacing: 0.35em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  border-radius: 30px;
  color: black;
  background-color: #fff;
}

/* General styles */
* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.nonselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
/* App container */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #000;
}

/* Navigation */
.app-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: transparent;
  color: #000;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.app-nav-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-title {
  font-size: 1.1rem;
  font-weight: bold;
}

/* Main content */
.app-main {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 1em 0em 1em;
}

.app-main-heading {
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 600;
  color: #000;
  margin-bottom: 1rem;
  max-width: 100%;
  text-align: center;
  padding: 0 1em;
  width: 100%;
}

@media (min-width: 800px) {
  .app-main-heading {
    max-width: 800px;
    width: 800px;
  }
}

.app-main-description {
  color: #555;
  max-width: 600px;
  font-size: 1em;
  letter-spacing: 0.35em;
  text-align: center;
}

/* Footer */
.app-footer {
  color: #8a8a8a;
  padding: 2rem 1em;
  border-top: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Changed from center to flex-start */
}

.app-footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.app-footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: 0.85em;
}

.app-footer-section-header {
  font-size: 1.05em;
  margin-bottom: 1em;
}

.app-footer-contact-item {
  text-align: left;
}

.section-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: relative;
  object-fit: cover;
  overflow: hidden;
}

.section-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(1.1);
}

.copyright-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.app-footer-copyright {
  font-size: 0.8em;
}
